import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/interactive-access',
    name: 'InteractiveAccess',
    component: () => import('~/views/interactiveAccess/InteractiveAccess.vue'),
    meta: { title: 'СКУД' }
  },

  // interactive access objects
  {
    path: '/interactive-access/interactive-access-objects',
    name: 'InteractiveAccessObjects',
    component: () => import('~/views/interactiveAccess/lists/interactiveAccessObjects/InteractiveAccessObjects.vue'),
    meta: { title: 'Объекты СКУД' }
  },
  {
    path: '/interactive-access/interactive-access-object-servers',
    name: 'InteractiveAccessObjectServers',
    component: () => import('~/views/interactiveAccess/lists/interactiveAccessObjects/InteractiveAccessObjectServers.vue'),
    meta: { title: 'Серверы СКУД' }
  },
  {
    path: '/interactive-access/interactive-access-object-types',
    name: 'InteractiveAccessObjectTypes',
    component: () => import('~/views/interactiveAccess/lists/interactiveAccessObjects/InteractiveAccessObjectTypes.vue'),
    meta: { title: 'Типы объектов СКУД' }
  },

  // House-cameras
  {
    path: '/interactive-access/cameras/house-cameras',
    name: 'HouseCameras',
    component: () => import('~/views/interactiveAccess/lists/cameras/HouseCameras.vue'),
    meta: { title: 'Камеры домов' }
  },

  {
    path: '/interactive-access/cameras/object-groups',
    name: 'ObjectGroups',
    component: () => import('~/views/interactiveAccess/lists/cameras/ObjectGroups.vue'),
    meta: { title: 'Группы камер и объектов доступа' }
  },

  // { START } users
  // premises intercoms

  {
    path: '/interactive-access/users/premises-intercoms',
    name: 'PremisesIntercoms',
    component: () =>
      import('~/views/interactiveAccess/lists/users/PremisesIntercoms.vue'),
    meta: { title: 'Пользователи объектов доступа' }
  },

  // { START } downloads
  {
    path: '/interactive-access/users/import',
    name: 'ImportPremisesIntercoms',
    component: () =>
      import('~/views/interactiveAccess/file-analysis/ImportPremisesIntercoms.vue'),
    meta: { title: 'Загрузка пользователей объектов доступа' }
  },

  // { START } users
  // long process
  {
    path: '/interactive-access/owners-lists/premises/import-premise-gis-number',
    name: 'InteractiveAccessImportPremiseGisNumber',
    component: () =>
      import(
        '~/views/interactiveAccess/file-analysis/ImportPremiseGisNumber.vue'
      ),
    meta: { title: 'Импорт номеров помещений ГИС' }
  },

  // Journals
  {
    path: '/interactive-access/journal/interactive-access-objects-activations-log',
    name: 'InteractiveAccessObjectsActivationsLog',
    component: () => import('~/views/interactiveAccess/lists/journal/InteractiveAccessObjectsActivationsLog.vue'),
    meta: { title: 'Журнал активации объектов СКУД' }
  },

  {
    path: '/interactive-access/journal/interactive-access-objects-push-log',
    name: 'InteractiveAccessObjectsPushLog',
    component: () => import('~/views/interactiveAccess/lists/journal/InteractiveAccessObjectsPushLog.vue'),
    meta: { title: 'Журнал вызовов домофона' }
  },

  {
    path: '/interactive-access/journal/delegated-rights',
    name: 'DelegatedRights',
    component: () => import('~/views/interactiveAccess/lists/journal/DelegatedRights.vue'),
    meta: { title: 'Выданные права доступа' }
  },

  {
    path: '/interactive-access/journal/user-log-in-formation',
    name: 'UserLogInformation',
    component: () => import('~/views/interactiveAccess/lists/journal/UserLogInformation.vue'),
    meta: { title: 'Информация о событиях пользователя' }
  },

  // Addresses

  {
    path: '/interactive-access/groups-of-districts',
    name: 'InteractiveAccessGroupsOfDistricts',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessGroupsOfDistricts.vue'),
    meta: { title: 'Группы районов' }
  },

  {
    path: '/interactive-access/home-groups',
    name: 'InteractiveAccessHomeGroups',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessHomeGroups.vue'),
    meta: { title: 'Районы' }
  },

  {
    path: '/debtor-relations/houses',
    name: 'InteractiveAccessHouses',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessHouses.vue'),
    meta: { title: 'Дома' }
  },
  
  {
    path: '/interactive-access/accounts',
    name: 'InteractiveAccessAccounts',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessAccounts.vue'),
    meta: { title: 'Лицевые счета' }
  },
  
  {
    path: '/interactive-access/account/:id?',
    name: 'InteractiveAccessAccount',
    component: () => import('~/views/interactiveAccess/list-items/addresses/InteractiveAccessAccount.vue'),
    meta: { title: 'Лицевой счет' }
  },
  
  {
    path: '/interactive-access/premises',
    name: 'InteractiveAccessPremises',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessPremises.vue'),
    meta: { title: 'Помещения' }
  },
  
  {
    path: '/interactive-access/mobile-accounts',
    name: 'InteractiveAccessMobileAccounts',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessMobileAccounts.vue'),
    meta: { title: 'Мобильные пользователи' }
  },
  
  {
    path: '/interactive-access/mobile-account-devices',
    name: 'InteractiveAccessMobileAccountDevices',
    component: () => import('~/views/interactiveAccess/lists/addresses/InteractiveAccessMobileAccountDevices.vue'),
    meta: { title: 'Устройства' }
  },
  // { END } downloads
  // { START } long process
  {
    path: '/interactive-access/ats-fill-premises-by-house-processor',
    name: 'AtsFillPremisesByHouseProcessor',
    component: () => import('~/views/interactiveAccess/long-process/AtsFillPremisesByHouseProcessor.vue'),
    meta: { title: 'Записать данные АТС в помещения' }
  },
  {
    path: '/interactive-access/account-from-tko-template-processor',
    name: 'InteractiveAccess.AccountFromTkoTemplateProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountFromTkoTemplateProcessor.vue'),
    meta: { title: 'Обновить ИЖКУ по результатам обработки шаблона ЛС ТКО' }
  },
  {
    path: '/interactive-access/accounts-csv-file-processor',
    name: 'InteractiveAccess.AccountsCsvFileProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountsCsvFileProcessor.vue'),
    meta: { title: 'Выгрузить ЛС/ЕЛС в CSV' }
  },
  {
    path: '/interactive-access/accounts-excel-file-processor',
    name: 'InteractiveAccess.AccountsExcelFileProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountsExcelFileProcessor.vue'),
    meta: { title: 'Выгрузить ЛС/ЕЛС в Excel' }
  },
  {
    path: '/interactive-access/create-cr-accounts-processor',
    name: 'InteractiveAccess.CreateCRAccountsProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/CreateCRAccountsProcessor.vue'),
    meta: { title: 'Создать лицевые счета для кап.ремонта' }
  },
  {
    path: '/interactive-access/export-houses-to-excel-processor',
    name: 'InteractiveAccess.ExportHousesToExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesToExcelProcessor.vue'),
    meta: { title: 'Выгрузить доп. информацию по домам (XLSX)' }
  },
  {
    path: '/interactive-access/export-houses-without-fias-processor',
    name: 'InteractiveAccess.ExportHousesWithoutFiasProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesWithoutFiasProcessor.vue'),
    meta: { title: 'Выгрузить дома без ФИАС в CSV файл' }
  },
  {
    path: '/interactive-access/fill-template-tko-accounts-processor',
    name: 'InteractiveAccess.FillTemplateTKOAccountsProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/FillTemplateTKOAccountsProcessor.vue'),
    meta: { title: 'Выгрузить ЛС ТКО' }
  },
  {
    path: '/interactive-access/ExportPremiseGisNumberProcessor',
    name: 'ExportPremiseGisNumberProcessor',
    component: () => import('~/views/interactiveAccess/long-process/ExportPremiseGisNumberProcessor.vue'),
    meta: { title: 'Экспорт номеров помещений ГИС в Excel' }
  },
  {
    path: '/interactive-access/house-creation-master-processor',
    name: 'InteractiveAccess.HouseCreationMasterProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseCreationMasterProcessor.vue'),
    meta: { title: 'Мастер создания дома' }
  },
  {
    path: '/interactive-access/house-fiases-processor',
    name: 'InteractiveAccess.HouseFiasesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseFiasesProcessor.vue'),
    meta: { title: 'Загрузить ФИАСы' }
  },
  {
    path: '/interactive-access/houses-for-tko-contract-processor',
    name: 'InteractiveAccess.HousesForTkoContractProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HousesForTkoContractProcessor.vue'),
    meta: { title: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)' }
  },
  {
    path: '/interactive-access/house-type-by-premise-type-processor',
    name: 'InteractiveAccess.HouseTypeByPremiseTypeProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseTypeByPremiseTypeProcessor.vue'),
    meta: { title: 'Изменение типа домов по кол-ву помещений' }
  },
  {
    path: '/interactive-access/import-houses-from-excel-processor',
    name: 'InteractiveAccess.ImportHousesFromExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ImportHousesFromExcelProcessor.vue'),
    meta: { title: 'Загрузить доп. информацию по домам (XLSX)' }
  },
  {
    path: '/interactive-access/premises-not-linked-to-account-remove-processor',
    name: 'InteractiveAccess.PremisesNotLinkedToAccountRemoveProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/PremisesNotLinkedToAccountRemoveProcessor.vue'),
    meta: { title: 'Удалить помещения, не привязанные к ЛС' }
  },
  {
    path: '/interactive-access/update-houses-addresses-processor',
    name: 'InteractiveAccess.UpdateHousesAddressesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/UpdateHousesAddressesProcessor.vue'),
    meta: { title: 'Обновление адресов домов' }
  },
  // { END } long process
]