// baseHttp.ts
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosResponseHeaders
} from 'axios';
import { getAccessToken, resetAccessToken, resetAccountInfo } from '../utils/authService';
import notify from './notify';

/**
 * @interface IHeaders - Интерфейс, определяющий структуру заголовков запроса
 */
interface IHeaders {
  [key: string]: string;
}

/**
 * @interface IAxiosPluginConfig - Интерфейс, определяющий конфигурацию для плагина Axios
 */
interface IAxiosPluginConfig {
  baseURL: string;
  headers?: IHeaders;
}

/**
 * @class Http - Класс, представляющий обертку над Axios для выполнения HTTP-запросов
 */
class Http {
  /**
   * @member {AxiosInstance} instance - Экземпляр Axios для выполнения HTTP-запросов
   */
  public instance: AxiosInstance;

  /**
   * Создает экземпляр класса Http.
   * @constructor
   * @param {IAxiosPluginConfig} config - Конфигурация для плагина Axios
   */
  constructor(config: IAxiosPluginConfig) {
    this.instance = axios.create({
      baseURL: config.baseURL,
      headers: {
        client: 'web',
        'Content-Type': 'application/json',
        ...(config.headers || {})
      }
    });

    this.setupResponseInterceptors();
  }

  /**
   * Настраивает перехватчики ответов для обработки успешных ответов и ошибок
   * @private
   */
  private setupResponseInterceptors(): void {
    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        try {
          config.headers = config.headers || {};
          config.headers['acx'] = getAccessToken() || '';
          return config;
        } catch (error) {
          console.error('Ошибка при установке токена в заголовок запроса:', error);
          return Promise.reject(error);
        }
      },
      (error: AxiosError) => {
        console.error('Ошибка запроса:', error);
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.status !== 200 && response.data.Message) {
          this.handleErrorResponse(response);
        }

        if (
          !response.data?.isSucceed &&
          response.data?.error?.length
        ) {
          this.handleErrorResponse(response);
        }

        return response;
      },
      (error: AxiosError) => {
        this.handleRequestError(error);
        return Promise.reject(error);
      }
    );
  }

  /**
   * Обрабатывает ошибки в ответе, показывая уведомления или выполняя обработку ошибок
   * @private
   * @param {AxiosResponse} response - Объект ответа Axios
   */
  private handleErrorResponse(response: AxiosResponse): void {
    notify.show({
      type: 'error',
      title: 'Ошибка',
      text: response.data.Message || response.data.error
    });
  }

  /**
   * Обрабатывает ошибки запроса, включая истечение срока действия токена и перенаправление на страницу входа
   * @private
   * @param {AxiosError} error - Объект ошибки Axios
   */
  private handleRequestError(error: AxiosError): void {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Неавторизованный доступ - токен недействителен:', error.response);

        resetAccessToken();
        resetAccountInfo();

        if (process.env.NODE_ENV === 'production') {
          const currentLocation = document.location.href;
          if (currentLocation && currentLocation !== '#/login') {
            document.location.replace(`${currentLocation.split('#')[0]}#/login`);
          }
        }
      } else {
        console.error('Ошибка в ответе запроса:', error.response);
      }
    } else {
      console.error('Ошибка запроса:', error);
    }
  }

  /**
   * Устанавливает базовый URL для экземпляра Axios
   * @param {string} baseURL - Базовый URL для установки
   */
  public setBaseURL(baseURL: string): void {
    this.instance.defaults.baseURL = baseURL;
  }

  /**
   * Устанавливает пользовательские заголовки для экземпляра Axios
   * @param {Record<string, string>} headers - Заголовки для установки
   */
  public setHeaders(headers: Record<string, string>): void {
    this.instance.defaults.headers = {
      ...this.instance.defaults.headers,
      ...headers
    };
  }

  /**
   * Выполняет HTTP-запрос методом GET
   * @param {string} url - URL для GET-запроса
   * @param {AxiosRequestConfig} [config] - Опциональная конфигурация Axios запроса
   * @returns {Promise<T>} - Обещание, разрешающееся данными из ответа
   */
  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      const response = await this.instance.get<T>(url, config);

      return response;
    } catch (error) {
      this.handleRequestError(error as AxiosError);
      throw error;
    }
  }

  /**
   * Выполняет HTTP-запрос методом POST
   * @param {string} url - URL для POST-запроса
   * @param {*} [data] - Данные для включения в POST-запрос
   * @param {InternalAxiosRequestConfig | AxiosRequestConfig} [config] - Опциональная конфигурация Axios запроса
   * @returns {Promise<T>} - Обещание, разрешающееся данными из ответа
   */
  public async post<T>(url: string, data?: any, config?: InternalAxiosRequestConfig | AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      const response = await this.instance.post<T>(url, data, config);

      return response;
    } catch (error) {
      this.handleRequestError(error as AxiosError);
      throw error;
    }
  }
}

export default Http;
