import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/access/',
    name: 'Access',
    component: () => import('~/views/access/AccessMain.vue'),
    meta: {
      title: 'Доступы',
    }
  },
  {
    path: '/access/users',
    name: 'Access.Users',
    component: () => import('~/views/access/lists/UserList.vue'),
    meta: {
      title: 'Пользователи',
    }
  },
  {
    path: '/access/users-ads',
    name: 'Access.AdsUsers',
    component: () => import('~/views/access/lists/AdsUsers.vue'),
    meta: {
      title: 'Пользователи АДС',
    }
  },
  {
    path: '/access/roles',
    name: 'Access.Roles',
    component: () => import('~/views/access/lists/RoleList.vue'),
    meta: {
      title: 'Роли сотрудников в базе',
    }
  },
];