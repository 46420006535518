import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/debtor-relations',
    name: 'DebtorRelations',
    component: () => import('~/views/debtors/DebtorRelations.vue'),
    meta: { title: 'Работа с должниками' }
  },
  // References
  {
    path: '/houses-of-debtors',
    name: 'HousesOfDebtors',
    component: () => import('~/views/debtors/lists/references/HousesOfDebtors.vue'),
    meta: { title: 'Дома для работы с должниками' }
  },

  {
    path: '/debtor-templates',
    name: 'DebtorTemplates',
    component: () => import('~/views/debtors/lists/references/DebtorTemplates.vue'),
    meta: { title: 'Шаблоны' }
  },

  {
    path: '/debtor-relations/debt-cases-pre-trial-works-types',
    name: 'DebtCasesPreTrialWorksTypes',
    component: () => import('~/views/debtors/lists/references/DebtCasesPreTrialWorksTypes.vue'),
    meta: { title: 'Шаблоны' }
  },

  {
    path: '/judicial-sectors',
    name: 'JudicialSectors',
    component: () => import('~/views/debtors/lists/references/JudicialSectors.vue'),
    meta: { title: 'Судебные участки' }
  },

  {
    path: '/claimants',
    name: 'Claimants',
    component: () => import('~/views/debtors/lists/references/Claimants.vue'),
    meta: { title: 'Взыскатели' }
  },

  {
    path: '/claimants-types',
    name: 'ClaimantsTypes',
    component: () => import('~/views/debtors/lists/references/ClaimantsTypes.vue'),
    meta: { title: 'Типы взыскателей' }
  },

  {
    path: '/users-for-debtors',
    name: 'UsersForDebtors',
    component: () => import('~/views/debtors/lists/references/UsersForDebtors.vue'),
    meta: { title: 'Пользователи для работы с должниками' }
  },

  {
    path: '/debt-proceeding-stages',
    name: 'DebtProceedingStages',
    component: () => import('~/views/debtors/lists/references/DebtProceedingStages.vue'),
    meta: { title: 'Этапы работы' }
  },

  // Debt cases

  {
    path: '/debtor-relations/auto-debt-case-dispatchers',
    name: 'AutoDebtCaseDispatchers',
    component: () => import('~/views/debtors/lists/debtCases/AutoDebtCaseDispatchers.vue'),
    meta: { title: 'Исполнители по делам должников' }
  },

  {
    path: '/debtor-relations/company-profile',
    name: 'CompanyProfile',
    component: () => import('~/views/debtors/lists/generalReferences/CompanyProfile.vue'),
    meta: { title: 'Организации' }
  },

  {
    path: '/debtor-relations/houses',
    name: 'DebtorRelationsHouses',
    component: () => import('~/views/debtors/lists/generalReferences/DebtorRelationsHouses.vue'),
    meta: { title: 'Дома' }
  },

  {
    path: '/debtor-relations/accounts',
    name: 'DebtorRelationsAccounts',
    component: () => import('~/views/debtors/lists/generalReferences/DebtorRelationsAccounts.vue'),
    meta: { title: 'Лицевые счета' }
  },

  {
    path: '/debtor-relations/account/:id?',
    name: 'DebtorRelationsAccount',
    component: () => import('~/views/debtors/list-items/generalReferences/DebtorRelationsAccount.vue'),
    meta: { title: 'Лицевой счет' }
  },

  {
    path: '/debtor-relations/payments-info',
    name: 'PaymentsInfo',
    component: () => import('~/views/debtors/lists/generalReferences/PaymentsInfo.vue'),
    meta: { title: 'Платежи' }
  },

  {
    path: '/debtor-relations/bills',
    name: 'Bills',
    component: () => import('~/views/debtors/lists/generalReferences/BillList.vue'),
    meta: { title: 'Квитанции' }
  },

  {
    path: '/debtor-relations/access/users',
    name: 'AccessUsers',
    component: () => import('~/views/debtors/lists/generalReferences/AccessUsers.vue'),
    meta: { title: 'Пользователи' }
  },

  // Reports
  {
    path: '/debtor-relations/debtors-by-periods-report',
    name: 'DebtorsByPeriodsReport',
    component: () => import('~/views/debtors/reports/DebtorsByPeriodsReport.vue'),
    meta: { title: 'Виды комментариев по досудебной работе' }
  },
  {
    path: '/debtor-relations/claimants-report',
    name: 'ClaimantsReport',
    component: () => import('~/views/debtors/reports/ClaimantsReport.vue'),
    meta: { title: 'Отчет по взыскателям (оплатам) за период' }
  },
  {
    path: '/debtor-relations/debtor-cases-statuses-report',
    name: 'DebtorsCasesStatusesReport',
    component: () => import('~/views/debtors/reports/DebtorsCasesStatusesReport.vue'),
    meta: { title: 'Отчет по статусам работы с должниками' }
  },
  {
    path: '/debtor-relations/debtor-idents-statement-report',
    name: 'DebtorsIdentsStatementReport',
    component: () => import('~/views/debtors/reports/DebtorsIdentsStatementReport.vue'),
    meta: { title: 'База лицевых счетов (ведомость по должникам)' }
  },
  {
    path: '/debtor-relations/debtors-payment-report',
    name: 'DebtorsPaymentsReport',
    component: () => import('~/views/debtors/reports/DebtorsPaymentsReport.vue'),
    meta: { title: 'Оплата по должникам' }
  },
  {
    path: '/debtor-relations/debtors-informing-effectiveness-report',
    name: 'DebtorsInformingEffectivenessReport',
    component: () => import('~/views/debtors/reports/DebtorsInformingEffectivenessReport.vue'),
    meta: { title: 'Отчет по эффективности автообзвонов' }
  },  
  // Long process
  {
    path: '/debtor-relations/debtors-idents-statement-report-processor',
    name: 'DebtorsIdentsStatementReportProcessor',
    component: () => import('~/views/debtors/long-process/DebtorsIdentsStatementReportProcessor.vue'),
    meta: { title: 'База лицевых счетов (ведомость по должникам)' }
  },
  {
    path: '/debtor-relations/create-debt-cases-with-request-processor',
    name: 'CreateDebtCasesWithRequestProcessor',
    component: () => import('~/views/debtors/long-process/CreateDebtCasesWithRequestProcessor.vue'),
    meta: { title: 'Формирование дел должников' }
  },
  {
    path: '/debtor-relations/import-debtors-cases',
    name: 'ImportDebtorsCases',
    component: () => import('~/views/debtors/long-process/ImportDebtorsCases.vue'),
    meta: { title: 'Загрузка дел должников из файла' }
  },
];
