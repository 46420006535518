import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/one-c/',
    name: 'OneC',
    component: () => import('~/views/oneC/OneC.vue'),
    meta: {
      title: '1С',
    }
  },
  // {START} lists
  {
    path: '/one-c/archives',
    name: 'OneC.Archives',
    component: () => import('~/views/oneC/lists/ArchivesOneC.vue'),
    meta: {
      title: 'Архив информационных баз 1С',
    }
  },
  {
    path: '/one-c/profiles',
    name: 'OneC.Profiles',
    component: () => import('~/views/oneC/lists/ProfilesOneC.vue'),
    meta: {
      title: 'Профили пользователей',
    }
  },
  {
    path: '/one-c/contracts',
    name: 'OneC.Contracts',
    component: () => import('~/views/oneC/lists/ContractsOneC.vue'),
    meta: {
      title: 'Договоры',
    }
  },
  {
    path: '/one-c/bills',
    name: 'OneC.Bills',
    component: () => import('~/views/oneC/lists/BillsOneC.vue'),
    meta: {
      title: 'Счета на оплату',
    }
  },
  {
    path: '/one-c/payments',
    name: 'OneC.Payments',
    component: () => import('~/views/oneC/lists/PaymentsOneC.vue'),
    meta: {
      title: 'Оплаты',
    }
  },
  {
    path: '/one-c/acts',
    name: 'OneC.Acts',
    component: () => import('~/views/oneC/lists/ActsOneC.vue'),
    meta: {
      title: 'Акты оказанных услуг',
    }
  },
  // {END} lists
];