import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/site-company/',
    name: 'SiteCompany',
    component: () => import('~/views/siteCompany/SiteCompany.vue'),
    meta: {
      title: 'Сайт компании ЖКХ',
    }
  },
  // {START} lists
  {
    path: '/site-company/additional-options',
    name: 'AdditionalOptions',
    component: () => import('~/views/siteCompany/lists/AdditionalOptions.vue'),
    meta: {
      title: 'Дополнительные параметры',
    }
  },
  {
    path: '/site-company/site-partner-links',
    name: 'SitePartnerLinks',
    component: () => import('~/views/siteCompany/lists/SitePartnerLinks.vue'),
    meta: {
      title: 'Партнерские ссылки на сайте',
    }
  },
  {
    path: '/site-company/news',
    name: 'News',
    component: () => import('~/views/siteCompany/lists/News.vue'),
    meta: {
      title: 'Новости',
    }
  },
  {
    path: '/site-company/documents',
    name: 'Documents',
    component: () => import('~/views/siteCompany/lists/Documents.vue'),
    meta: {
      title: 'Документы',
    }
  },
  {
    path: '/site-company/group-documents',
    name: 'GroupDocuments',
    component: () => import('~/views/siteCompany/lists/GroupDocuments.vue'),
    meta: {
      title: 'Группы Документов',
    }
  },
  {
    path: '/site-company/articles',
    name: 'Articles',
    component: () => import('~/views/siteCompany/lists/Articles.vue'),
    meta: {
      title: 'Статьи',
    }
  },
  {
    path: '/site-company/photo-reports',
    name: 'PhotoReports',
    component: () => import('~/views/siteCompany/lists/PhotoReports.vue'),
    meta: {
      title: 'Фотоотчеты',
    }
  },
  {
    path: '/site-company/files',
    name: 'Files',
    component: () => import('~/views/siteCompany/lists/Files.vue'),
    meta: {
      title: 'Файлы',
    }
  },
  {
    path: '/site-company/houses',
    name: 'SiteCompany.Houses',
    component: () => import('~/views/siteCompany/lists/Houses.vue'),
    meta: {
      title: 'Файлы',
    }
  },
  {
    path: '/site-company/gallery-albums',
    name: 'GalleryAlbums',
    component: () => import('~/views/siteCompany/lists/GalleryAlbums.vue'),
    meta: {
      title: 'Альбомы',
    }
  },
  {
    path: '/site-company/gallery-images',
    name: 'GalleryImages',
    component: () => import('~/views/siteCompany/lists/GalleryImages.vue'),
    meta: {
      title: 'Изображения',
    }
  },
  {
    path: '/site-company/gallery-videos',
    name: 'GalleryVideos',
    component: () => import('~/views/siteCompany/lists/GalleryVideos.vue'),
    meta: {
      title: 'Видео',
    }
  },
  {
    path: '/site-company/site-menu-items',
    name: 'SiteMenuItems',
    component: () => import('~/views/siteCompany/lists/SiteMenuItems.vue'),
    meta: {
      title: 'Меню',
    }
  },
  // {END} lists
  // {START} long-process
  {
    path: '/site-company/export-houses-to-excel-processor',
    name: 'SiteCompany.ExportHousesToExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesToExcelProcessor.vue'),
    meta: {
      title: 'Выгрузить доп. информацию по домам (XLSX)',
    }
  },
  {
    path: '/site-company/export-houses-without-fias-processor',
    name: 'SiteCompany.ExportHousesWithoutFiasProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesWithoutFiasProcessor.vue'),
    meta: {
      title: 'Выгрузить дома без ФИАС в CSV файл',
    }
  },
  {
    path: '/site-company/house-creation-master-processor',
    name: 'SiteCompany.HouseCreationMasterProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseCreationMasterProcessor.vue'),
    meta: {
      title: 'Мастер создания дома',
    }
  },
  {
    path: '/site-company/house-fiases-processor',
    name: 'SiteCompany.HouseFiasesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseFiasesProcessor.vue'),
    meta: {
      title: 'Загрузить ФИАСы',
    }
  },
  {
    path: '/site-company/houses-for-tko-contract-processor',
    name: 'SiteCompany.HousesForTkoContractProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HousesForTkoContractProcessor.vue'),
    meta: {
      title: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)',
    }
  },
  {
    path: '/site-company/house-type-by-premise-type-processor',
    name: 'SiteCompany.HouseTypeByPremiseTypeProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseTypeByPremiseTypeProcessor.vue'),
    meta: {
      title: 'Изменение типа домов по кол-ву помещений',
    }
  },
  {
    path: '/site-company/import-houses-from-excel-processor',
    name: 'SiteCompany.ImportHousesFromExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ImportHousesFromExcelProcessor.vue'),
    meta: {
      title: 'Загрузить доп. информацию по домам (XLSX)',
    }
  },
  {
    path: '/site-company/premises-not-linked-to-account-remove-processor',
    name: 'SiteCompany.PremisesNotLinkedToAccountRemoveProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/PremisesNotLinkedToAccountRemoveProcessor.vue'),
    meta: {
      title: 'Удалить помещения, не привязанные к ЛС',
    }
  },
  {
    path: '/site-company/update-houses-addresses-processor',
    name: 'SiteCompany.UpdateHousesAddressesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/UpdateHousesAddressesProcessor.vue'),
    meta: {
      title: 'Обновление адресов домов',
    }
  },
  // {END} long-process
];